import React from "react"
import Page from "./../../templates/page.js"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"
import { Icon, Small, Caption, Warning } from "./../../components/helpers.js"
import Gallery from "./../../components/gallery.js"
import Share from "./../../components/share.js"
import { RiShoppingCartLine, RiQuestionLine } from "react-icons/ri";
import { FiMail, FiFile } from "react-icons/fi";
import styled from 'styled-components'
import Img from "gatsby-image"


export default ({ data }) => {

    const Price = styled.h2`
        margin-top:0.5em;    
        font-size:2em;
        margin-bottom:-0.3em;
    `;

    const main = <>
        {/* <Gallery style={{marginTop: '1em'}} img={[data.img2.childImageSharp.fluid, data.img1.childImageSharp.fluid, data.img3.childImageSharp.fluid]} /> */}
        <Img fadeIn={false} fluid={data.img9.childImageSharp.fluid} alt="Adapter" />

        <h2>Product description</h2>

        <p>
            This Bluetooth adapter is easy to install, and adds Bluetooth music playback to the original radio of selected old BMW models before 1996 and IBUS
            by emulating the CD changer.
            The adapter is compatible with BMW E30, E31, E32, E34 and E36 models, depending on the head unit. See the list below.
        </p>

        <p>The adapter can be installed behind the dashboard and plugs into the CD changer connector of your radio. This also means that if present, the CD changer can no
            longer be used.</p>

        <h3>Features</h3>

        <ul>
            <li>Good quality music playback by putting the radio in CD mode</li>
            <li>Radio buttons can be used for play, pause, and next/previous track</li>
            <li>Bluetooth volume can be controlled with buttons on the radio</li>
            <li>Adapter remembers and tries to connect to the last connected phone</li>
            <li>Some basic status information is shown on the radio display</li>
        </ul>

        <p><i>The adapter does not support showing title or artist information on the display. The adapter does not support hands-free calling. It is for audio playback only.</i></p>

        <h3>Compatible head units</h3>

        <Warning><b><center>Models or version numbers that are not listed below are not supported. Questions about compatibility with other head units will not be answered.</center></b></Warning>

        <p>The adapter has been succesfully tested on the BMW Business RDS radio (Philips PH7850), the BMW Bavaria C Business RDS (Pioneer KE-92ZBM), the Pioneer KE-91ZBM and the Alpine CM5901:</p>

        <Img fadeIn={false} fluid={data.img4.childImageSharp.fluid} alt="BMW Business RDS" />
        <div><Caption>BMW Business RDS (Philips PH7850)</Caption></div>

        <Img fadeIn={false} fluid={data.img5.childImageSharp.fluid} alt="BMW Bavaria C Business RDS" />
        <div><Caption>BMW Bavaria C Business RDS (Pioneer KE-92ZBM)</Caption></div>

        <Img fadeIn={false} fluid={data.img8.childImageSharp.fluid} alt="BMW Pioneer KE-91ZBM" />
        <div><Caption>Pioneer KE-91ZBM Version 4</Caption></div>

        <Img fadeIn={false} fluid={data.img6.childImageSharp.fluid} alt="Alpine CM5901E" />
        <div><Caption>Alpine CM5901E</Caption></div>

        <Share url="https://maakbaas.com/shop/bmw-bluetooth-adapter/" />

    </>;

    const side = <div className="about"><Price>€ 119.00</Price>

        <Small>Ships from the Netherlands. Potential taxes for delivery outside of the EU are at the risk of the buyer.</Small>
{/* 
        <Small>Built to order in 2-3 weeks.</Small>

        <button
            style={{ width: '100%', fontSize: '1.05em' }}
            className="snipcart-add-item"
            data-item-id="0x001.6"
            data-item-price="119"
            data-item-url="/shop/bmw-bluetooth-adapter"
            data-item-has-taxes-included="true"
            data-item-description="Bluetooth audio adapter for BMW head units"
            data-item-image={data.snip.childImageSharp.fixed.src}
            data-item-name="BMW Bluetooth Adapter">
            <Icon><RiShoppingCartLine /></Icon> Buy this product
        </button> */}

        <p style={{color: '#cc3333'}}><b>Temporarily out of stock. Use the <Link style={{color: '#cc3333'}} to="/contact/"><b>contact form</b></Link> to request an alert when the shop reopens.</b></p>

        <a href="/manual-maakbaas-0x001.pdf" style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
            <button style={{ width: '100%', backgroundColor: '#fff', border: '1px solid #000', color: '#000', fontSize: '0.85em', padding: '0.6em', marginTop: '-6px' }}>
                <Icon><FiFile /></Icon> View user manual
            </button></a>

    </div>;

    return (
        <>
            <Helmet>
                <title>BMW Bluetooth Adapter - Maakbaas Shop</title>
                <meta name="description" content="Buy an easy to install BMW Bluetooth adapter that is compatible with selected E30, E31, E32, E34 and E36 models, depending on the installed head unit." />
            </Helmet>
            <Page swap={true} title="BMW Bluetooth Adapter" content={main} sidebar={side}
                sidebar2={<p><Icon><RiQuestionLine /></Icon> <Link to="/shop/faq">Shop FAQ</Link>
                    <br /><Icon><FiMail /></Icon> <Link to="/contact">Contact</Link></p>} />
        </>);

}

export const query = graphql`
{
    snip: file(relativePath: { eq: "v2.jpg" }) {
        childImageSharp {
            fixed(width: 200) {
                src
            }
        }
    }

    img1: file(relativePath: { eq: "DSCF5666.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "DSCF5663.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    img3: file(relativePath: { eq: "DSCF5655.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img4: file(relativePath: { eq: "ph7850.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img5: file(relativePath: { eq: "ke92zbm.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img6: file(relativePath: { eq: "cm5903l.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img7: file(relativePath: { eq: "be2450.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    img8: file(relativePath: { eq: "ke91zbm.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
        
    img9: file(relativePath: { eq: "v2.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`